/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getScrollTriggers, setScrollTrigger} from "utils/scrolltriggers";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components), {ResourcePanel, Pin, InfoNugget, Obl, Headline, InfoItem, Link, CareInstructions, CareItem} = _components;
  if (!CareInstructions) _missingMdxReference("CareInstructions", true);
  if (!CareItem) _missingMdxReference("CareItem", true);
  if (!Headline) _missingMdxReference("Headline", true);
  if (!InfoItem) _missingMdxReference("InfoItem", true);
  if (!InfoNugget) _missingMdxReference("InfoNugget", true);
  if (!Link) _missingMdxReference("Link", true);
  if (!Obl) _missingMdxReference("Obl", true);
  if (!Pin) _missingMdxReference("Pin", true);
  if (!ResourcePanel) _missingMdxReference("ResourcePanel", true);
  return React.createElement(React.Fragment, null, React.createElement(ResourcePanel, {
    id: "figure01",
    image: "figure01",
    imageAlt: "lorem ipsum",
    roundTopCorners: true,
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure01",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Central centrifugal cicatricial alopecia (CCCA) is the most common scarring alopecia (hair loss) among Black women."), React.createElement(_components.p, null, "Hair loss from CCCA occurs primarily in the central (crown) part of the scalp.")), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "The hair loss radiates outward in a centrifugal or circular pattern and is usually gradual although some people experience a rapid progression of the hair loss."), React.createElement(_components.p, null, "CCCA causes destruction of the hair follicles and scarring leading to permanent hair loss."), React.createElement(_components.p, null, "For more info - ", React.createElement(Obl, {
    to: "https://skinofcolorsociety.org/patient-dermatology-education/central-centrifugal-cicatricial-alopecia-ccca/",
    title: "Skin Of Color Society info on dermatology"
  }, "https://skinofcolorsociety.org/patient-dermatology-education/central-centrifugal-cicatricial-alopecia-ccca/"))), React.createElement(Pin, {
    id: "figure01",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure02",
    image: "figure02",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure02",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Causes"), React.createElement(_components.p, null, "There are likely multiple causes of CCCA."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "Recently, a gene variant was discovered in about 25% of patients with CCCA, but the exact role of this variant in the occurrence of CCCA is unclear."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "Various hairstyling practices have been studied as potentially causing CCCA, but none has been definitively linked to the condition."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "A combination of factors such as infections (bacterial and fungal), genetics, autoimmune disease, and other unknown factors may play a role in this disease."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "More research is needed to understand the extent to which any or all of these factors contribute to the development of CCCA."), "\n"), "\n"), React.createElement(_components.p, null, "For more info - ", React.createElement(Obl, {
    to: "https://jamanetwork.com/journals/jamadermatology/fullarticle/2768748",
    title: "Dermatology Journal for the American Medical Association"
  }, "https://jamanetwork.com/journals/jamadermatology/fullarticle/2768748"))), React.createElement(Pin, {
    id: "figure02",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure03",
    image: "figure03",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure03",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Symptoms"), React.createElement(_components.p, null, "At its onset, CCCA presents as hair breaking and thinning in the crown of the scalp."), React.createElement(_components.p, null, "The area of thinning hair expands over time and progresses to more severe, permanent hair loss. Some patients experience burning, itching, tenderness, redness, scaling (flaking), and acne-like pimples or bumps in the region of hair loss."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, {
    noBorder: true
  }, "Scar tissue builds up around the hair follicle, stopping hair growth."), "\n"), "\n")), React.createElement(InfoNugget, null, React.createElement(_components.p, null, React.createElement(Headline, {
    level: 2
  }, "How is CCCA Diagnosed?"), "\nA diagnosis of CCCA is often made through a clinical examination of the scalp."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "A dermatoscope, which is a handheld magnifying tool, may be used to view the affected area."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, {
    noBorder: true
  }, "The physician may also perform a scalp biopsy (a small portion of the scalp skin is removed and examined under microscopic) to support the diagnosis."), "\n"), "\n")), React.createElement(Pin, {
    id: "figure03",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure04",
    image: "figure04",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure04",
    type: "start",
    height: 400,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Please Note"), React.createElement(_components.p, null, "Though early treatment such as corticosteroids and anti-inflammatory agents can help slow its progression, unfortunately, there is ", React.createElement(Link, {
    to: "#care-instructions"
  }, "no cure for CCCA"), ".")), React.createElement(Pin, {
    id: "figure04",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "video01",
    video: "L6zAd2Crhkw",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "video01",
    type: "start",
    height: 300,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Hear Dr. Chesahna Kindred of the ", React.createElement(Obl, {
    to: "https://kindredhairandskin.com/",
    title: "Dr. Kindred's Practice"
  }, "Kindred Hair and Skin Center"), " share more on how address CCCA.")), React.createElement(Pin, {
    id: "video01",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(CareInstructions, null, React.createElement(Headline, {
    level: 3
  }, "Treatments"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "The main goal of treating CCCA is to relieve its symptoms, to slow or stop further hair loss and promote hair regrowth in areas not permanently scarred."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(_components.strong, null, "Mild CCCA"), " is often treated with a topical steroid medication applied by the patient at home and sometimes combined with local corticosteroid injections in the scalp performed by the physician at 4- to 8-week intervals."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(_components.strong, null, "More severe CCCA"), " will combine these treatments with oral medications that treat inflammation by suppressing the immune system (immunosuppressants)."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Women with CCCA may also consider changing their hair care practices. While there is no clear evidence linking hair styling to CCCA, avoiding the use of potentially damaging chemicals (relaxers should be applied by a professional only) and hair styles (", React.createElement(_components.a, {
    href: "/traction-alopecia"
  }, "tight braids, extensions, weaves"), ") and minimizing the use of heated styling tools (hot combs, hooded hair dryers, blow dryers, flat irons) will reduce further damage. Patients are also encouraged to wash their hair at least once every 1 to 2 weeks as this helps decrease inflammation of the scalp."), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
